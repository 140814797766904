<template>
  <div class="tab-wrap row-flex align-center">
    <div
      class="tab-item"
      :class="{ active: activeTab == item.id }"
      v-for="(item, index) in tabData"
      @click="selectTavHandler(item.id)"
      :key="index"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  name: "tab-wrap",
  props: {
    tabData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  watch: {
    tabData: {
      deep: true,
      handler(val) {
        if (!this.activeTab) {
          this.activeTab = val?.[0]?.id;
          this.$emit("change", this.activeTab);
        }
      },
    },
  },
  methods: {
    selectTavHandler(tabId) {
      this.activeTab = tabId;
      this.$emit("change", this.activeTab);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-wrap {
  width: 100%;
  overflow: scroll;
  margin-top: 1rem;
  background: #ffffff;
  .tab-item {
    font-size: 1.6rem;
    color: #282828;
    padding: 1.2rem 0;
    font-weight: bold;
    border-top: 0.4rem solid transparent;
    border-bottom: 0.4rem solid transparent;
    margin: 0 2rem;
    white-space: nowrap;
  }

  .active {
    border-bottom-color: #f7cb46;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
}
</style>
