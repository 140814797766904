<template>
  <div class="header-wrap row-flex justify-center">
    <div class="header-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.header {
  &-wrap {
    background: #f7cb46;
    width: 100vw;
    padding: 1.6rem 0;
    border-bottom: 0.05rem solid #d9d9d9;
  }
  &-title {
    font-size: 2rem;
    font-weight: bold;
    color: #282828;
    letter-spacing: 0.5rem;
  }
}
</style>
