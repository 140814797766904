import axios from "axios";
import { Toast } from "vant";

const service = axios.create({
  // baseURL: "http://localhost:8080/",
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000,
});

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    if (response.status === 200) {
      return response.data;
    } else {
      Toast(response.data.msg || "请求错误，请联系管理员");
      return Promise.reject(response.data.msg);
    }
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default service;
