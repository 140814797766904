<!-- eslint-disable space-before-function-paren -->
<template>
  <div>
    <div class="home-wrap" ref="container">
      <div class="header-wrap">
        <header-wrap :title="waterName" />
        <tab :tab-data="packageTypeData" @change="tabChangeHandler" />
      </div>

      <div class="select-list">
        <div
          v-for="(item, index) in categoryData"
          :key="index"
          class="select-item column-flex"
          @click="showProductListHandler(item)"
        >
          <div class="title">{{ item.name }}</div>
          <div class="content">{{ getSelectName(item.id) }}</div>
          <div class="row-flex justify-end">
            <div class="num-wrap row-flex align-center">
              <div
                class="num-item"
                @click.stop="numClickHandler('sub', item.id)"
              >
                -
              </div>
              <div class="num-item">{{ getSelectNum(item.id) }}</div>
              <div
                class="num-item"
                @click.stop="numClickHandler('add', item.id)"
              >
                +
              </div>
            </div>
          </div>
        </div>

        <div
          class="select-item column-flex"
          @click="showPickerHandler('invoice')"
        >
          <div class="title">开票</div>
          <div class="row-flex align-center justify-between">
            <div class="content">{{ getSelectName("invoice") }}</div>
            <div class="btn">选择</div>
          </div>
        </div>

        <div
          class="select-item column-flex"
          @click="showPickerHandler('package')"
        >
          <div class="title">租期</div>
          <div class="row-flex align-center justify-between">
            <div class="content">{{ getSelectName("package") }}</div>
            <div class="btn">选择</div>
          </div>
        </div>
      </div>
      <div class="bottom-wrap row-flex align-center">
        <!--      <div class="total-text">合计</div>-->
        <!--      <div class="price">-->
        <!--        <span style="font-size: 1.4rem">￥</span>-->
        <!--        {{ subTotal }}/月-->
        <!--      </div>-->
        <div class="quote-price-btn" @click="quotePriceHandler">立即询价</div>
      </div>
    </div>

    <div class="screenshot-btn" @click="screenShotClickHandler"></div>

    <select-product
      ref="selectProduct"
      :category-id="currentCategoryId"
      :package-type-id="packageTypeId"
      @change="selectProductHandler"
    />

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        value-key="name"
        :columns="pickerColumns"
        @cancel="showPicker = false"
        @confirm="confirmPickerHandler"
      ></van-picker>
    </van-popup>
  </div>
</template>

<script>
import headerWrap from "@/components/header.vue";
import Tab from "@/components/tab.vue";

import selectProduct from "./components/select-product.vue";

import Watermark from "@/utils/waterMark";

import { Dialog, Toast } from "vant";

import html2canvas from "html2canvas";

import {
  queryPackageTypeList,
  queryPackageList,
  queryCategoryList,
  getCodeDetail,
} from "@/api/index";

export default {
  components: {
    headerWrap,
    Tab,
    selectProduct,
  },
  data() {
    return {
      packageTypeData: [],
      packageData: [],
      categoryData: [],
      packageTypeId: null,
      currentCategory: null,
      selectProduct: {},
      productNum: {}, // 商品数量
      percent: 0, // 溢价率
      waterName: "凯威共享网络", // 水印名称
      currentCategoryId: null,
      showPicker: false,
      pickerColumns: [],
    };
  },
  computed: {
    getSelectName() {
      return function (id) {
        return this.selectProduct[id]?.name || "请选择";
      };
    },
    getSelectNum() {
      return function (id) {
        return this.productNum[id] || 1;
      };
    },
    subTotal() {
      let formula = null;
      let invoice = 1.04;
      let totalPrice = 0;
      let total = 0;
      let product = this.selectProduct;
      for (const key of Object.keys(product)) {
        if (key === "package" && product[key].formula) {
          formula = product[key].formula;
        }
        if (key === "invoice" && product[key].percent) {
          invoice = product[key].percent;
        } else if (product[key]) {
          const num = this.productNum[key] || 1;
          total += parseFloat(product[key].price * num || 0);
        }
      }
      if (formula) {
        if (formula && total > 0) {
          totalPrice = eval(formula.replace("{total}", total));
        } else {
          totalPrice = total;
        }
        return Math.ceil(totalPrice * (1 + this.percent) * invoice);
      }
      return Math.ceil(totalPrice * invoice);
    },
  },
  created() {
    const { id } = this.$route.query;
    if (id) {
      this.queryCodeDetail(id);
    } else {
      this.$nextTick(() => {
        Watermark.set(this.waterName);
      });
    }
    this.getPackageTypeData();
    this.getCategoryList();
  },
  methods: {
    quotePriceHandler() {
      if (!this.selectProduct?.package?.name) {
        return Toast("请选择租期");
      }
      Dialog.alert({
        message: `合计：￥${this.subTotal}/月`,
        theme: "round-button",
      }).then(() => {});
    },
    async queryCodeDetail(id) {
      const { data } = await getCodeDetail(id);
      const { pricePercent, status, waterName } = data;
      if (parseInt(status) !== 1) {
        return Toast("此二维码已失效，请联系销售人员重新获取");
      }
      if (pricePercent > 0) {
        this.percent = pricePercent / 100;
      }
      this.waterName = waterName || "凯威共享网络";
      this.$nextTick(() => {
        Watermark.set(waterName);
      });
    },
    async getPackageTypeData() {
      const { data } = await queryPackageTypeList();
      this.packageTypeData = data;
    },
    async getCategoryList() {
      const { data } = await queryCategoryList({ status: 1 });
      this.categoryData = data;
      this.initData();
    },
    initData() {
      for (const d of this.categoryData) {
        this.$set(this.selectProduct, d.id, null);
        this.$set(this.productNum, d.id, 1);
      }
      this.$set(this.selectProduct, "package", { name: null, formula: null });
    },
    tabChangeHandler(tabId) {
      if (tabId !== this.packageTypeId) {
        this.initData();
      }
      this.packageTypeId = tabId;
      this.getPackageData(tabId);
    },
    async getPackageData(id) {
      const { data } = await queryPackageList(id);
      this.packageData = data;
    },
    showProductListHandler(item) {
      this.showProductList = true;
      this.currentCategoryId = item.id;
      this.$refs.selectProduct.init();
    },
    selectProductHandler(product) {
      this.$set(this.selectProduct, this.currentCategoryId, product);
      console.log(this.selectProduct);
    },
    numClickHandler(type, categoryId) {
      let num = this.productNum[categoryId] || 1;
      if (type === "add") {
        //增加
        num += 1;
      } else {
        // 减少
        num -= 1;
      }
      this.$set(this.productNum, categoryId, num);
    },
    showPickerHandler(type) {
      this.currentCategoryId = type;
      if (type === "invoice") {
        this.pickerColumns = [
          {
            id: 1,
            name: "不开票",
            percent: 1.03,
          },
          {
            id: 2,
            name: "普通发票",
            petcent: 1.04,
          },
          {
            id: 3,
            name: "增值税专用发票",
            percent: 1.06,
          },
        ];
        this.showPicker = true;
      } else if (type === "package") {
        this.pickerColumns = this.packageData;
        this.showPicker = true;
      }
    },
    confirmPickerHandler(val) {
      this.$set(this.selectProduct, this.currentCategoryId, val);
      this.showPicker = false;
    },
    screenShotClickHandler() {
      html2canvas(this.$refs.container, {
        useCORS: true,
        width: this.$refs.container.scrollWidth,
        height: this.$refs.container.scrollHeight,
        windowWidth: this.$refs.container.scrollWidth,
        windowHeight: this.$refs.container.scrollHeight,
        x: 0,
        y: 0,
      }).then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");
        const createDom = document.createElement("a");
        document.body.appendChild(createDom);
        createDom.href = dataURL;
        createDom.download = this.waterName;
        createDom.click();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrap {
  position: sticky;
  top: 0;
}
.home-wrap {
  width: 100vw;
  background: #f8f8f8;
  height: 100vh;
}

.select-list {
  margin-top: 1rem;
  padding: 0 2rem;
  background: #ffffff;
  padding-bottom: 10rem;

  .select-item {
    padding: 0.8rem 0;
    border-bottom: 0.05rem solid #f0f0f0;
    .title {
      color: #6f6f6f;
      font-size: 1.5rem;
      margin-bottom: 0.8rem;
    }

    .content {
      font-size: 1.6rem;
      color: #282828;
    }

    .num-wrap {
      border: 0.05rem solid #c2c2c2;
      border-radius: 0.5rem;
      .num-item {
        flex: 1;
        font-size: 1.5rem;
        color: #6f6f6f;
        padding: 0 0.8rem;
        line-height: 2.8rem;
        border-right: 0.05rem solid #c2c2c2;
      }

      .num-item:nth-last-child(1) {
        border-right: none;
      }
    }
    .btn {
      font-size: 1.5rem;
      color: #6f6f6f;
    }
  }
}

.screenshot-btn {
  position: fixed;
  z-index: 10;
  bottom: 10rem;
  right: 0;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  background: #f7cb46;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  border: 0.4rem solid #f7cb46;
  background-image: url("../../assets/screenshots.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.bottom-wrap {
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0rem 0rem 0.65rem 0rem rgba(191, 191, 191, 0.51);
  bottom: 0;
  padding: 1.2rem 2rem;
  width: 100vw;
  box-sizing: border-box;

  .quote-price-btn {
    width: 100%;
    background: #f7cb46;
    color: #282828;
    font-size: 1.6rem;
    border-radius: 2.2rem;
    padding: 1.1rem 2.2rem;
    text-align: center;
  }
  .total-text {
    font-size: 1.5rem;
    color: #6f6f6f;
  }

  .price {
    color: #ba0c0c;
    font-size: 1.7rem;
    margin-left: 1.6rem;
  }
}
</style>
