import request from "@/utils/request";

export async function queryPackageTypeList() {
  const result = await request.get("/hire/packageType/list");
  return result;
}

export async function queryPackageList(id) {
  const result = await request.get(`/hire/package/list?packageTypeId=${id}`);
  return result;
}

export async function queryCategoryList(params) {
  const result = await request.get(`/hire/category/list?status=${params.status}`,);
  return result;
}

export async function queryProductList(categoryId, packageTypeIds,name) {
  const result = await request.get(
    `/hire/product/list?categoryId=${categoryId}&packageTypeIds=${packageTypeIds}&name=${name}`
  );
  return result;
}

export async function getCodeDetail(codeId){
  const result = await request.get(`/hire/sale/code/detail?codeId=${codeId}`)
  return result
}
