<template>
  <van-popup
    v-model="showProductList"
    :lock-scroll="false"
    position="right"
    round
    closeable
    close-icon-position="top-left"
  >
    <div class="popup-wrap">
      <van-field
        placeholder="请输入商品名称"
        v-model="productKeyWord"
        left-icon="search"
        class="input-wrap"
        @input="productKeyWordChange"
      />
      <div class="product-list">
        <div
          class="product-item"
          @click="selectProductHandler(product)"
          v-for="(product, index) in productListData"
          :key="index"
        >
          {{ product.name }}
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { queryProductList } from "@/api/index";
export default {
  props: ["categoryId", "packageTypeId"],
  data() {
    return {
      productKeyWord: null,
      productListData: [],
      showProductList: false,
    };
  },
  methods: {
    init() {
      this.showProductList = true;
      this.productKeyWord = null;
      this.productListData = [];
    },
    async productKeyWordChange() {
      const { data } = await queryProductList(
        this.categoryId,
        this.packageTypeId,
        this.productKeyWord
      );
      this.productListData = data;
    },
    selectProductHandler(product) {
      this.$emit("change", product);
      this.showProductList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-wrap {
  width: 90vw;
  height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
  padding: 6rem 2rem 1rem 2rem;
  .input-wrap {
    background: #f8f8f8;
    border-radius: 2rem;
    height: 4.2rem;
    font-size: 1.5rem;
  }
  .product-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .product-item {
      padding: 1.6rem 0;
      font-size: 1.5rem;
      color: #282828;
      border-bottom: 0.05rem solid #e0e0e0;
    }
  }
}
</style>
